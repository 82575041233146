import React, { useState } from 'react';
import { TextField, Button, List, ListItem, Typography } from '@mui/material';

const Picking = () => {
  const [soNumber, setSoNumber] = useState('');
  const [pickList, setPickList] = useState([]);

  const handleScanSO = () => {
    // Fetch the pick list based on SO number
    setPickList([
      { id: 1, product: 'Product A', quantity: 2, location: 'Aisle 1' },
      { id: 2, product: 'Product B', quantity: 1, location: 'Aisle 3' },
    ]);
  };

  const handleCompletePicking = () => {
    // Logic to finalize picking and update the database
  };

  return (
    <div>
      <Typography variant="h4">Picking Module</Typography>
      <TextField
        label="Scan or Enter Sales Order"
        value={soNumber}
        onChange={(e) => setSoNumber(e.target.value)}
      />
      <Button onClick={handleScanSO}>Scan SO</Button>

      <List>
        {pickList.map(item => (
          <ListItem key={item.id}>
            <Typography>{item.product} - Qty: {item.quantity} - Location: {item.location}</Typography>
            <Button>Scan and Pick</Button>
          </ListItem>
        ))}
      </List>

      <Button variant="contained" color="primary" onClick={handleCompletePicking}>Complete Picking</Button>
    </div>
  );
}

export default Picking;
