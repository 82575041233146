import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation,Navigate  } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, BottomNavigation, BottomNavigationAction } from '@mui/material';
 
import InventoryIcon from '@mui/icons-material/Inventory';
import Home from './components/Home'; 
import { useNavigate  } from 'react-router-dom';
import Receiving from './components/Receiving';
import Inventory from './components/Inventory';
import Picking from './components/Picking'; 
import Packing from './components/Packing'; 
import QrScanner from './components/QrScanner'; 
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LogoutIcon from '@mui/icons-material/Logout'; // Import Logout Icon
import { GiHandTruck } from "react-icons/gi";
import { FaTruckLoading } from "react-icons/fa";
import { GiBoxUnpacking } from "react-icons/gi";
const Master = ({onLogout}) => {
    return (
      
      <Main onLogout={onLogout} />
  
     
      );
}

function Main( {onLogout } ) {
    const location = useLocation(); 
    const [value, setValue] = useState(location.pathname); 
    const navigate = useNavigate();
   
    useEffect(() => {
      setValue(location.pathname);
    }, [location.pathname]);
  
    const getTitle = () => {
      switch (value) {
        case '/goodsreceiving':
          return 'GoodsReceiving';
        case '/inventory':
          return 'Inventory';
        case '/picking':
          return 'Picking'; 
        
        default:
          return 'WMS';
      }
    };
  
    const hideBottomNavigation = location.pathname.includes('/gr/') || location.pathname.includes('/inventory/') || location.pathname.includes('/picking/')  ;
    const showCustomAppBar = location.pathname.includes('/gr/') || location.pathname.includes('/inventory/') || location.pathname.includes('/picking/')  ;
    const handleLogout = () => {
        if (onLogout) {
          onLogout(); // Call the logout function passed as a prop
        }
      };
    return (
      <>
   
        {!showCustomAppBar && (
          <AppBar position="static" style={{ 
            background: 'linear-gradient(to left, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)'
          }}>
              <Toolbar>
                {/* Add flexGrow to Typography to push the IconButton to the right */}
                <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                {getTitle()}
                </Typography>
                
                {/* Align Logout Button to the right */}
                <IconButton color="inherit" onClick={handleLogout}>
                <LogoutIcon />
                </IconButton>
            </Toolbar>
          </AppBar>
        )}
  
  
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="receiving" element={<Receiving />} />
          <Route path="inventory" element={<Inventory />} />
          <Route path="picking" element={<Picking />} />
          <Route path="packing" element={<QrScanner />} />
           
        </Routes>
       {/* Footer Component */}
        {/* <Footer />  */}
        {!hideBottomNavigation && (
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            showLabels
            style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          >
            <BottomNavigationAction
              label="Home"
              icon={<WarehouseIcon />}
              component={Link}
              to="/master/home"
              value="/master/home"
            />
            <BottomNavigationAction
              label="Receiving"
              icon={<FaTruckLoading size={25} />}
              component={Link}
              to="/master/receiving"
              value="/master/receiving"
            />
            <BottomNavigationAction
              label="Inventory"
              icon={<InventoryIcon />}
              component={Link}
              to="/master/inventory"
              value="/master/inventory"
            />
            <BottomNavigationAction
              label="Picking"
              icon={<GiHandTruck size={25} />}
              component={Link}
              to="/master/picking"
              value="/master/picking"
            />
            
            <BottomNavigationAction
              label="Packing"
              icon={<GiBoxUnpacking size={25} />}
              component={Link}
              to="/master/packing"
              value="/master/packing"
            />
            
         
          </BottomNavigation>
        )}
      
      </>
    );
  }

export default Master;