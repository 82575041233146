import React, { useState, useRef, useEffect } from 'react';
import { BrowserQRCodeReader } from '@zxing/browser';

const App = () => {
  const [scannedResult, setScannedResult] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const videoRef = useRef(null); // Ref for the video element
  const codeReaderRef = useRef(null); // Ref for the QR code reader instance

  // Function to request and activate the user's camera
  const activateCamera = async () => {
    if (videoRef.current) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' }, // Use rear camera on mobile devices
        });
        videoRef.current.srcObject = stream;
        videoRef.current.setAttribute('playsinline', true); // Required for iOS devices
        videoRef.current.play();

        // Wait for the video to be ready
        videoRef.current.onloadedmetadata = () => {
          setIsScanning(true);
        };
      } catch (err) {
        console.error('Error accessing the camera', err);
      }
    }
  };

  // Function to handle the QR code scanning result
  const handleScanResult = (result, err) => {
    if (result) {
      alert(`Scanned QR Code: ${result.getText()}`);
      setScannedResult(result.getText());
      setIsScanning(false); // Stop scanning after result

      // Stop all video tracks when done
      const stream = videoRef.current?.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    }

    if (err) {
      console.error('Error scanning QR code', err);
    }
  };

  // UseEffect to handle QR scanning when camera is activated
  useEffect(() => {
    if (isScanning && videoRef.current) {
      // Initialize the code reader and start scanning
      codeReaderRef.current = new BrowserQRCodeReader();
      
      // Use requestAnimationFrame to ensure video element is fully rendered
      const startScanning = () => {
        if (videoRef.current) {
          codeReaderRef.current.decodeFromVideoElement(videoRef.current, handleScanResult);
        } else {
          requestAnimationFrame(startScanning);
        }
      };

      startScanning();

      // Clean up the code reader and stop the video stream when component unmounts
      return () => {
        codeReaderRef.current?.reset();
        const stream = videoRef.current?.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      };
    }
  }, [isScanning]);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2>QR Code Scanner</h2>

      <button onClick={activateCamera}>
        {isScanning ? 'Stop Scanning' : 'Start Scanning'}
      </button>

      {/* Display the camera view in video element */}
      {isScanning && (
        <div style={{ margin: '20px 0' }}>
          <video
            ref={videoRef}
            style={{ width: '100%', height: 'auto' }}
            playsInline
          />
        </div>
      )}

      {/* Display scanned result */}
      {scannedResult && (
        <div>
          <h3>Scanned QR Code:</h3>
          <p>{scannedResult}</p>
        </div>
      )}
    </div>
  );
};

export default App;
