import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Master from './Master';  
import Cookies from 'js-cookie';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
 
  
  useEffect(() => {
    const token = Cookies.get('token');
    setIsAuthenticated(!!token);
  }, []);

 


  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    Cookies.remove('token', { path: '/' });
    setIsAuthenticated(false);
  };

 
  
  // Get the last path from localStorage or default to /dashboard
  const lastPath = localStorage.getItem('lastPath') || '/master';
  console.log({lastPath});
  return (
    <Router>
     
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to={lastPath} /> : <Login onLoginSuccess={handleLoginSuccess} />}
        />
        <Route
          path="/master/*"
          element={isAuthenticated ? <Master onLogout={handleLogout} /> : <Navigate to="/" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
