import React, { useState } from 'react';
import { TextField, Button, List, ListItem, Typography } from '@mui/material';

const Inventory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [inventory, setInventory] = useState([
    { id: 1, product: 'Product A', quantity: 100, location: 'Aisle 1' },
    { id: 2, product: 'Product B', quantity: 50, location: 'Aisle 3' },
  ]);

  const handleSearch = () => {
    // Search logic based on product or location
  };

  const handleUpdateQuantity = (id, newQuantity) => {
    setInventory(inventory.map(item => item.id === id ? { ...item, quantity: newQuantity } : item));
  };

  return (
    <div>
      <Typography variant="h4">Inventory Management</Typography>
      <TextField
        label="Search by Product or Location"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Button onClick={handleSearch}>Search</Button>

      <List>
        {inventory.map(item => (
          <ListItem key={item.id}>
            <Typography>{item.product} - Location: {item.location} - Qty: {item.quantity}</Typography>
            <TextField
              label="Adjust Quantity"
              type="number"
              onChange={(e) => handleUpdateQuantity(item.id, e.target.value)}
            />
          </ListItem>
        ))}
      </List>

      <Button variant="contained" color="primary">Update Inventory</Button>
    </div>
  );
}

export default Inventory;
