import React, { useState, useRef, useEffect } from 'react';
import { Fab, Button, TextField, Typography } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import MUIDataTable from 'mui-datatables';
import { BrowserQRCodeReader } from '@zxing/browser'; // Import ZXing QR code reader

const Receiving = () => {
  const initialData = [
    { po: 'P0001', product: 'Staplers', quantity: 20, received: 0 },
    { po: 'P0002', product: 'Sticky Tapes', quantity: 30, received: 0 },
    { po: 'P0003', product: 'Scissors', quantity: 30, received: 0 },
    { po: 'P0004', product: 'Note Holders', quantity: 22, received: 0 },
    { po: 'P0005', product: 'Pen', quantity: 45, received: 0 }
  ];

  const [data, setData] = useState(initialData);
  const [filteredData, setFilteredData] = useState([]);
  const [scannedPO, setScannedPO] = useState('');
  const [scannedProduct, setScannedProduct] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [scannedResult, setScannedResult] = useState(''); // State for scanned result
  const videoRef = useRef(null); // Ref for video element

  const scanPO = (po) => {
    const filteredItems = data.filter((item) => item.po === po);
    setFilteredData(filteredItems);
    setScannedPO(po);
  };

  const scanProduct = (product) => {
    setFilteredData((prevData) =>
      prevData.map((item) =>
        item.product === product
          ? { ...item, received: item.received + 1 }
          : item
      )
    );

    setData((prevData) =>
      prevData.map((item) =>
        item.product === product && item.po === scannedPO
          ? { ...item, received: item.received + 1 }
          : item
      )
    );
  };

  const handlePOInput = (event) => {
    setScannedPO(event.target.value);
  };

  const handleProductInput = (event) => {
    setScannedProduct(event.target.value);
  };

  const handleSubmitPO = () => {
    scanPO(scannedPO);
  };

  const handleSubmitProduct = () => {
    scanProduct(scannedProduct);
  };

  // Start scanning for QR codes
  useEffect(() => {
    if (isScanning) {
      const codeReader = new BrowserQRCodeReader();
      codeReader.decodeFromVideoDevice(null, videoRef.current, (result, err) => {
        if (result) {
          const scannedText = result.getText();
          setScannedResult(scannedText); // Update scanned result
          setScannedPO(scannedText);
          scanPO(scannedText);
          setIsScanning(false); // Stop scanning once a QR code is found
        }
      });

      return () => {
        codeReader.reset();
      };
    }
  }, [isScanning]);

  const tableData = filteredData.map((item) => [
    item.po,
    item.product,
    item.quantity,
    item.received,
  ]);

  return (
    <div>
      {/* DataTable for Receiving */}
      <MUIDataTable
        title={'Receiving'}
        data={tableData}
        columns={['PO', 'Product', 'Quantity', 'Quantity Received']}
      />

      {/* Enter PO and Product */}
      <div style={{ marginTop: '20px' }}>
        <TextField
          label="Enter PO"
          variant="outlined"
          value={scannedPO}
          onChange={handlePOInput}
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleSubmitPO}>
          Submit PO
        </Button>

        <TextField
          label="Enter Product"
          variant="outlined"
          value={scannedProduct}
          onChange={handleProductInput}
          style={{ marginLeft: '10px', marginRight: '10px' }}
        />
        <Button variant="contained" color="secondary" onClick={handleSubmitProduct}>
          Submit Product
        </Button>
      </div>

      {/* Display scanned result */}
      {scannedResult && (
        <Typography variant="h6" style={{ marginTop: '20px' }}>
          Scanned Result: {scannedResult}
        </Typography>
      )}

      {/* Video element for QR code scanning */}
      {isScanning && (
        <div>
          <video ref={videoRef} style={{ width: '100%', marginTop: '20px' }} />
        </div>
      )}

      {/* Button to toggle QR scanning */}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setIsScanning(!isScanning)}
        style={{ marginTop: '10px' }}
      >
        {isScanning ? 'Stop Scanning' : 'Start QR Scanning'}
      </Button>

      <Fab
        color="error"
        aria-label="scanner"
        style={{
          position: 'fixed',
          bottom: 64,
          right: 16,
        }}
        onClick={() => setIsScanning(true)} // Start QR scanner
      >
        <QrCodeScannerIcon />
      </Fab>
    </div>
  );
};

export default Receiving;
